<template>
  <div class="w-full pt-5 pb-5">
    <div class="flex">
      <div style="width: 230px">
        <h1 class="text-xl text-left font-extrabold ml-4 mt-3">
          Variable Earnings
        </h1>
      </div>
      <div class="w-7/12 mt-4"></div>
    </div>
    <div class="px-3">
      <card class="p-5 mt-6">
        <p class="w-9/12 text-jet text-base mb-6">
          Variable earnings more commonly refers to a combination of pay types
          used to motivate employees to focus on key areas of the business. Here
          you can track and process such earnings for your employees.
        </p>
        <card v-if="!showError" class="p-4 mt-6">
          <card-footer
            reloadcard
            printcard
            @reload="reload()"
            @sortType="handleSort($event)"
            @searchResult="handleSearch($event)"
            @actionModal="viewFilterTable($event)"
          >
            <div slot="leftItems">
              <div class="w-auto h-15 flex">
                <c-select
                  placeholder="--Select--"
                  v-model="variableMonth"
                  @onChange="handleMonthChange(variableMonth)"
                  :options="month"
                  :disabled="!AVMonth"
                  variant="w-full"
                  label="Payrun Cycle"
                  class=""
                />
              </div>
            </div>
          </card-footer>
        </card>
      </card>
      <div v-if="!showError" class="w-full mt-10">
        <Table
          :headers="headers"
          :loading="loading"
          :items="tableData"
          aria-label="variable table"
          class="tw-px-12"
          :pagination-list="metaData"
          @page="handlePage($event)"
          page-sync
          @itemsPerPage="handleItemsPerPage($event)"
        >
          <template v-slot:item="{ item }">
            <div v-if="item.photo">
              <img
                class=""
                :src="item.data.photo"
                v-if="item.data.photo"
                alt="photo"
                style="height: 30px; width: 30px; border-radius: 5px"
              />
              <div
                style="height: 35px; width: 35px; border-radius: 5px"
                class="text-blueCrayola border text-center font-semibold pt-2"
                v-else
              >
                {{ $getInitials(`${item.data.fname} ${item.data.lname}`) }}
              </div>
            </div>
            <span v-else-if="item.fname" class="tw-text-center">
              {{ item.data.fname }}
              {{ item.data.lname }}
            </span>
            <span v-else-if="item.userDesignation" class="tw-text-center">
              {{
                item.data.userDesignation ? item.data.userDesignation.name : "-"
              }}
            </span>
            <span v-else-if="item.orgFunction" class="tw-text-center">
              {{ !item.data.orgFunction ? "-" : item.data.orgFunction.name }}
            </span>
            <span
              v-else-if="item.currentVariableEarnings"
              class="tw-text-center"
            >
              <Badge
                :label="convertToCurrency(item.data.currentVariableEarnings)"
                variant="primary cursor-pointer text-center"
                background-color="rgba(19, 181, 106, 0.15)"
                color="rgba(19, 181, 106, 1)"
                style="width: 7rem; padding: 0.5rem"
              />
            </span>
            <span v-else-if="item.outstandingAmount" class="tw-text-center">
              <Badge
                :label="convertToCurrency(item.data.outstandingAmount)"
                variant="primary cursor-pointer text-center"
                background-color="rgba(242, 201, 76, 0.15)"
                color="#E99323"
                style="width: 7rem; padding: 0.5rem"
              />
            </span>
            <span v-else-if="item.totalDeduction" class="tw-text-center">
              <Badge
                :label="convertToCurrency(item.data.totalDeduction)"
                variant="primary cursor-pointer text-center"
                background-color="rgba(234, 60, 83, 0.15);"
                color="#EA3C53"
                style="width: 7rem; padding: 0.5rem"
              />
            </span>
            <span v-else-if="item.totalAmountDue" class="tw-text-center">
              <Badge
                :label="convertToCurrency(item.data.totalAmountDue)"
                variant="primary cursor-pointer text-center"
                background-color="rgba(238, 238, 238, 1)"
                color="#878E99"
                style="width: 7rem; padding: 0.5rem"
              />
            </span>
            <div v-else-if="item.id">
              <Menu left top="-150" margin="24" class="my-2 p-0">
                <template v-slot:title>
                  <icon icon-name="more_icon" size="xs" />
                </template>
                <div style="width: 116px" class="py-3 px-2">
                  <div
                    class="flex py-1 px-3 more h-8 cursor-pointer"
                    @click="editEmployee(item.data)"
                  >
                    <icon
                      icon-name="icon-eye"
                      class-name="text-blueCrayola mr-2"
                      size="xsm"
                    />
                    <p class="pt-1">Open</p>
                  </div>
                </div>
              </Menu>
            </div>
          </template>
        </Table>
      </div>
      <div v-else>
        <error-page />
      </div>

      <FilterTable
        v-if="filterTable"
        :filter-data="filterArray"
        @close="closeFilter($event)"
        @getParams="searchFilter($event)"
      />
    </div>
  </div>
</template>

<script>
/*eslint-disable */
import SearchInput from "@scelloo/cloudenly-ui/src/components/search-input";
import CSelect from "@scelloo/cloudenly-ui/src/components/select";
import Table from "@scelloo/cloudenly-ui/src/components/table";
import Badge from "@scelloo/cloudenly-ui/src/components/badge";
import Card from "@/components/Card";
import CardFooter from "@/components/CardFooter";
import Icon from "@/components/Icon";
import FilterTable from "@/components/FilterTable";
import ErrorPage from "@/modules/Admin/error403";
import Menu from "@/components/Menu";

export default {
  name: "VariableEarnings",
  components: {
    Card,
    Menu,
    CardFooter,
    FilterTable,
    SearchInput,
    Icon,
    CSelect,
    ErrorPage,
    Table,
    Badge,
  },
  data() {
    return {
      loading: false,
      variableMonth: "",
      showError: false,
      AVMonth: "",
      month: [],
      metaData: {},
      headers: [
        { title: "", value: "photo", width: 8 },
        { title: "Name", value: "fname", width: 16 },
        { title: "Designation", value: "userDesignation", width: 16 },
        { title: "Outstanding", value: "outstandingAmount", width: 16 },
        {
          title: "Current Earning",
          value: "currentVariableEarnings",
          width: 16,
        },
        { title: "Current Deduction", value: "totalDeduction", width: 16 },
        { title: "Amount Due", value: "totalAmountDue", width: 16 },
        { title: "", value: "id", image: true },
      ],
      tableData: [],
      filterArray: [
        { header: "Location(s)", value: "locationId", optionsBox: [] },
        { header: "Level(s)", value: "levelId", optionsBox: [] },
        { header: "Function(s)", value: "functionId", optionsBox: [] },
        { header: "Designation(s)", value: "designationId", optionsBox: [] },
      ],
      filterTable: false,
    };
  },

  methods: {
    viewFilterTable(value) {
      if (value) {
        this.filterTable = true;
      }
    },

    handleItemsPerPage(itemNo) {
      this.itemsPerPage = itemNo;
      this.getVariablePay();
    },
    handlePage(pageNumber) {
      this.numberOfPage = pageNumber;
      this.getVariablePay();
    },

    getVariablePay() {
      const parsed = JSON.parse(this.variableMonth);
      const payload = { month: parsed.month, year: parsed.year };

      const pageNumber = this.numberOfPage
        ? `page=${this.numberOfPage}`
        : `page=${Number(this.metaData.page)}`;
      const itemPage = this.itemsPerPage ? `&perPage=${this.itemsPerPage}` : "";

      this.$_getVariablePay(
        `${payload.month}/${payload.year}?${pageNumber}${itemPage}`
      ).then((response) => {
        this.tableData = response.data.employees;
        this.loading = false;
      });
    },

    changeTableData(values) {
      const payload = `${values.month}/${values.year}/?`;
      this.loading = true;
      const newValue = values.newValue.toString();
      if (values.value == "location") {
        this.$_getVariablePay(`${payload}officeAddress=${newValue}`).then(
          (response) => {
            this.tableData = response.data.employees;
            this.loading = false;
          }
        );
      }
      if (values.value == "function") {
        this.$_getVariablePay(`${payload}functionId=${newValue}`).then(
          (response) => {
            this.tableData = response.data.employees;
            this.loading = false;
          }
        );
      }
      if (values.value == "employmentType") {
        this.$_getVariablePay(`${payload}employmentType=${newValue}`).then(
          (response) => {
            this.tableData = response.data.employees;
            this.loading = false;
          }
        );
      }
      if (values.value == "level") {
        this.$_getVariablePay(`${payload}level=${newValue}`).then(
          (response) => {
            this.tableData = response.data.employees;
            this.loading = false;
          }
        );
      }
    },
    queryUpdate() {
      this.loading = true;
      const parsed = JSON.parse(this.variableMonth);
      const payload = { month: parsed.month, year: parsed.year };
      const search = this.searchWord ? `fname=${this.searchWord}` : "";
      const sort = this.sortType ? `&sort=${this.sortType}` : "";
      this.$_getVariablePay(
        `${payload.month}/${payload.year}?${sort}${search}`
      ).then((response) => {
        this.tableData = response.data.employees;
        this.loading = false;
      });
    },
    handleSearch(searchWord) {
      if (searchWord) {
        this.searchWord = searchWord;
        this.queryUpdate();
      } else {
        this.searchWord = null;
        this.queryUpdate();
      }
    },
    handleSort(sortType) {
      this.sortType = sortType;
      this.queryUpdate();
    },
    async editEmployee(value) {
      try {
        await this.$handlePrivilege(
          "variableEarnings",
          "viewVariableEarningsDetails"
        );
        this.$router.push({
          name: "ViewVariableEarnings",
          params: { id: value.id },
          query: { month: value.month, year: value.year },
        });
      } catch (error) {
        this.$toasted.error(
          "Sorry you do not have privilege to perform this action",
          { duration: 6000 }
        );
      }
    },
    async getMonths() {
      await this.$_getPayrunMonths(`?payType=regular`).then((res) => {
        this.AVMonth = res.data.isSetup;

        this.month = res.data.duration.map((item) => ({
          name: item.name,
          id: JSON.stringify({ month: item.month, year: item.year }),
          // year: item.month
        }));
      });
    },
    handleMonthChange(data) {
      const parsed = JSON.parse(data);
      this.loading = true;
      const payload = {
        month: parsed.month,
        year: parsed.year,
      };
      // this.$router.push({query:{month: payload.month, year: payload.year}})
      this.setTableData(payload);
    },
    reload() {
      this.loading = true;
      const parsed = JSON.parse(this.variableMonth);
      const payload = {
        month: parsed.month,
        year: parsed.year,
      };
      this.setTableData(payload);
    },
    setTableData({ month, year }) {
      this.$_getVariablePay(`${month}/${year}?page=1&perPage=50`)
        .then((result) => {
          this.tableData = result.data.employees;
          this.metaData = result.data.meta;
          this.loading = false;
        })
        .catch(() => {
          this.loading = false;
        });
    },

    getAllFunctions() {
      this.$_getFunction().then((response) => {
        response.data.functions.map((functionVal) => {
          this.filterArray.forEach((filter) => {
            if (filter.header === "Function(s)") {
              filter.optionsBox.push({
                id: functionVal.id,
                name: functionVal.name,
              });
            }
          });
          return {};
        });
      });
    },

    getAllDesignations() {
      this.$_getDesignations("").then((response) => {
        response.data.designations.map((designation) => {
          this.filterArray.forEach((filter) => {
            if (filter.header === "Designation(s)") {
              filter.optionsBox.push({
                id: designation.id,
                name: designation.name,
              });
            }
          });
          return {};
        });
      });
    },

    getAllLocations() {
      this.$_getHumanarLocations("").then((response) => {
        response.data.outlets.map((outlet) => {
          this.filterArray.forEach((filter) => {
            if (filter.header === "Location(s)") {
              filter.optionsBox.push({
                id: outlet.id,
                name: outlet.name,
              });
            }
          });
          return {};
        });
      });
    },

    getAllLevels() {
      this.$_getLevels().then((response) => {
        response.data.levels.map((outlet) => {
          this.filterArray.forEach((filter) => {
            if (filter.header === "Level(s)") {
              filter.optionsBox.push({
                id: outlet.id,
                name: outlet.name,
              });
            }
          });
          return {};
        });
      });
    },

    closeFilter(val) {
      if (val) {
        this.filterTable = false;
      }
    },

    searchFilter(params) {
      if (this.variableMonth !== "") {
        const parsed = JSON.parse(this.variableMonth);
        const payload = { month: parsed.month, year: parsed.year };
        this.$_getVariablePay(
          `${payload.month}/${payload.year}?${params}`
        ).then((response) => {
          this.tableData = response.data.employees;
          this.loading = false;
        });
      } else {
        this.$toasted.error("Select payrun cycle", { duration: 5000 });
      }
      this.filterTable = false;
    },
  },

  async mounted() {
    this.loading = true;
    this.getAllFunctions();
    this.getAllDesignations();
    this.getAllLocations();
    this.getAllLevels();
    try {
      await this.$handlePrivilege("variableEarnings", "viewRegister");
      this.showError = false;
      try {
        await this.getMonths();
        if (this.month.length !== 0) {
          const data = JSON.parse(this.month[this.month.length - 1].id);
          const month = data.month;
          const year = data.year;
          this.setTableData({
            month,
            year,
          });
          this.variableMonth = JSON.stringify(data);
        } else {
          this.loading = false;
        }
      } catch (error) {
        this.loading = false;
      }
    } catch (error) {
      this.showError = true;
      this.loading = false;
    }
  },
};
</script>

<style scoped>
.icon {
  width: 24px;
  height: 24px;
  padding: 3px;
}
</style>
